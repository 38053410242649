const TRANSLATION = {
    //header
    "header.about": "About Me",
    "header.portfolio": "Portfolio",
    "header.skills": "Skills",
    "header.contact": "Contact",
    //body
    about: `Hello 👋, I'm Jean and I've been working as a developer since 2019. I worked as a Fullstack developer for some time, using Node.js on the backend, but my greatest experience is in frontend with React Native.
    
    Besides developing new features, I actively contribute to defining the architecture of applications, performance, code optimization, documentation, test coverage, and releasing updates to production. All while applying clean code concepts, design patterns, SOLID principles, responsive layouts, and agile development. I'm passionate about building successful products, teamwork, improving user experience, and efficiently solving problems.`,
    city: "SP, Brazil",
    experience: "Professional Experience",
    education: "Academic Background",
    contact: "Get in Touch!",
    basic: "Basic",
    intermediate: "Intermediate",
    advanced: "Advanced",
    currently: "Currently",
    "back-top": "Back to Top",
    "full-project": "View Project",
    "education.description": "Bachelor's degree in Software Engineering \nfrom",
    "portfolio.description": "A section with some projects I've worked on",
    "skills.description": "Learn a bit about my technical skills",
    //works
    "work1.title": "Fullstack Developer at Flores Online / Isabela Flores",
    "work1.description": `I actively participated in the development of 2 websites and 4 applications. In the apps, I worked from architecture definition to publishing, with a primary focus on frontend using React Native and backend with Node.js.

More details on my LinkedIn.`,
    "work2.title": "Frontend Developer at Compass.UOL",
    "work2.description": `I worked on refactoring a mobile project in React Native/TypeScript, from defining the technologies to be used, design patterns, documentation, code reviews, branch/version control, and I'm responsible for publishing in app stores.
    Currently, I continue with maintenance and implementing new features.

More details on my LinkedIn.`,
    //footer
    "footer.left.text1": "Coded by me, made with",
    "footer.left.text2": "Especially",
    "footer.left.text3": "for",
    "footer.left.text4": "practicing",
    "footer.left.text5": "",
    "footer.left.text6": "and learning",
    "footer.right.text1": "All the work I do",
    "footer.right.text2": "will always have",
    "footer.right.text3": "a lot of",
    "footer.right.text4": "effort",
    "footer.right.text5": "proactivity and"
}

export default TRANSLATION
